import Home from "./pages/Home/Home";

import BatteryAnalytics from "./pages/Products/BatteryAnalytics";
import BatteryOptimization from "./pages/Products/BatteryOptimization";
import BatteryCostFunction from "./pages/Products/BatteryCostFunction";
import BatteryControl from "./pages/Products/BatteryControl";
import BatterySimulationTool from "./pages/Products/BatterySimulationTool";
import BatteryCommunication from "./pages/Products/BatteryCommunication";

import AssetManager from './pages/Solutions/AssetManagers';
import EPCContractors from './pages/Solutions/EPCContractors';
import ProjectDevelopers from './pages/Solutions/ProjectDevelopers';
import IPP from './pages/Solutions/IPP';
import Utilities from './pages/Solutions/Utilities';
import CIBatteryInstallers from './pages/Solutions/CIBatteryInstallers';
import EnergyTraders from './pages/Solutions/EnergyTraders';
import Develop from './pages/Solutions/Develop';
import Build from './pages/Solutions/Build';
import Operate from './pages/Solutions/Operate';
import EndofLife from './pages/Solutions/EndofLife';

import Resources from "./pages/Resources/Resources";
import News from "./pages/Resources/News/News";
import Podcast from "./pages/Resources/Podcast/Podcast";
import Awards from "./pages/Resources/Awards/Awards";
import Demo from "./pages/Resources/Demo/Demo";
import Articles from "./pages/Resources/Articles/Articles";
import OptimizationAlgorithm from "./pages/Resources/Articles/OptimizationAlgorithm";
import DisPatchAndControl from "./pages/Resources/Articles/DispatchControl";
import RevolutionizingBatteryOptimizationARealSuccessStory from "./pages/Resources/Articles/RevolutionizingBatteryOptimizationARealSuccessStory";
import SimulationTool from "./pages/Resources/Articles/SimulationTool";
import BatteryAnalyticsTool from "./pages/Resources/Articles/BatteryAnalytics";
import BatteryStateofHealth from "./pages/Resources/Articles/BatteryStateofHealth";
import BatteryEnvironmentalConcerns from "./pages/Resources/Articles/BatteryEnvironmentalConcerns";
import GridStability from "./pages/Resources/Articles/GridStability";
import AIRevolution from "./pages/Resources/Articles/AIRevolution";
import BatteryData from "./pages/Resources/Articles/BatteryData";
import CostFunction from "./pages/Resources/Articles/CostFunction";
import PredictiveMaintenance from "./pages/Resources/Articles/PredictiveMaintenance";
import EnergyTrading from "./pages/Resources/Articles/EnergyTrading";
import BatteryLifePractices from "./pages/Resources/Articles/BatteryLifePractices";
import BESSandGridStability from "./pages/Resources/Articles/BESSandGridStability";
import StateofHealth from "./pages/Resources/Articles/StateofHealth";
import ImproveOperations from "./pages/Resources/Articles/ImproveOperations";


import Careers from "./pages/Careers/Careers";
import TechnicalSalesFT from "./pages/Careers/TechnicalSalesFT";
import FullStackDeveloperPT from "./pages/Careers/FullStackDeveloperPT";
import BusinessDevelopmentIN from "./pages/Careers/BusinessDevelopmentIN";
import MarketingIN from "./pages/Careers/MarketingIN";
import ContentCreationIN from "./pages/Careers/ContentCreationInternship";
import CloudEngineerPT from "./pages/Careers/CloudEngineerPT";
import EmbeddedEngineerPT from "./pages/Careers/EmbeddedEngineerPT";
import OpenPosition from "./pages/Careers/OpenPosition";

import About from "./pages/Home/About";
import Contact from "./pages/Home/Contact";

import Login from "./pages/Home/Login";

import PrivacyPolicy from "./pages/Other/PrivacyPolicy";
import Imprint from "./pages/Other/Imprint";
import TermsAndConditions from "./pages/Other/TermsAndConditions";
import Error404 from "./pages/Other/error404";
import HomeRedirect from "./pages/Other/HomeRedirect";

import optimizationicon from "./assets/icons/navbar/Optimization.svg";
import costfunctionicon from "./assets/icons/navbar/Cost-Function.svg";
import controlicon from "./assets/icons/navbar/Control.svg";
import analyticsicon from "./assets/icons/navbar/Analytics.svg";
import simulationtoolicon from "./assets/icons/navbar/Battery-Simulation-Tool.svg";
import communicationicon from "./assets/icons/navbar/Communication.svg";

import assetmanagericon from "./assets/icons/navbar/Asset-Manager.svg";
import installersicon from "./assets/icons/navbar/Installers.svg";
import energytradersicon from "./assets/icons/navbar/Energy-Traders.svg";
import epcicon from "./assets/icons/navbar/EPC.svg";
import ippicon from "./assets/icons/navbar/IPP.svg";
import projectdevelopersicon from "./assets/icons/navbar/Project-Developers.svg";
import utilitiesicon from "./assets/icons/navbar/Utilities.svg";

import developicon from "./assets/icons/navbar/Develop.svg";
import buildicon from "./assets/icons/navbar/Build.svg";
import operateicon from "./assets/icons/navbar/Operate.svg";
import endoflifeicon from "./assets/icons/navbar/End-Of-Life.svg";

const pageRoutes = [
  {
    path: "/", name: "Home", component: <Home />, navbar:false, footerbar: false, subnavbar:true,
  },
  {
    path: "/products", name: "Products", component: <HomeRedirect/>, navbar:true, footerbar: true, subnavbar:true, subfooterbar: true, categories:["Operation", "Development"],
    routes:[
      {
        path: "/products/battery-cost-function", 
        category:0, 
        name: "Battery Cost Function", 
        component: <BatteryCostFunction />, 
        navbar:true, 
        footerbar: true, 
        context:"Determine the cost of utilization of the battery", 
        contexticon: costfunctionicon,
      },
      {
        path: "/products/battery-analytics", 
        category:0, 
        name: "Battery Insights/Analytics", 
        component: <BatteryAnalytics />, 
        navbar:true, 
        footerbar: true, 
        context:"Leverage battery data to monitor safety and performance", 
        contexticon: analyticsicon,
      },
      {
        path: "/products/battery-control", 
        category:0, 
        name: "Battery Control", 
        component: <BatteryControl />, 
        navbar:true, 
        footerbar: true, 
        context:"Remotely set the battery operating limits and control real-time", 
        contexticon: controlicon,
      },
      {
        path: "/products/battery-optimization", 
        category:0, 
        name: "Battery Optimization", 
        component: <BatteryOptimization />, 
        navbar:true, 
        footerbar: true, 
        context:"Optimal operation for extended lifetime and improved revenues", 
        contexticon: optimizationicon,
      },
      {
        path: "/products/battery-simulation-tool", 
        category:1, 
        name: "Battery Simulation Tool", 
        component: <BatterySimulationTool />, 
        navbar:true, 
        footerbar: true, 
        context:"Identify the best size and determine its lifetime operation", 
        contexticon: simulationtoolicon,
      },
      {
        path: "/products/battery-communication", 
        category:1, 
        name: "Battery Communication", 
        component: <BatteryCommunication />, 
        navbar:true, 
        footerbar: true, 
        context:"Custom gateway development between battery and inverter/EMS", 
        contexticon: communicationicon,
      },
    ]
  },
  {
    path: "/solutions", name: "Solutions", component: <HomeRedirect/>, navbar:true, footerbar: true, subnavbar:true, subfooterbar: true, categories:["By Stakeholder", "By Project Phase"],
    routes:[
      {
        path: "/solutions/asset-managers", 
        category:0, 
        name: "Asset Managers", 
        component: <AssetManager />, 
        navbar:true, 
        footerbar: true, 
        context:"Monitor and optimize your battery asset performance",
        contexticon: assetmanagericon,
      },
      {
        path: "/solutions/ci-battery-installers", 
        category:0, 
        name: "C&I Battery Installers", 
        component: <CIBatteryInstallers />, 
        navbar:true, 
        footerbar: true, 
        context:"Assist in battery communication, remote monitoring and maintenance",
        contexticon: installersicon,
      },
      {
        path: "/solutions/energy-traders", 
        category:0, 
        name: "Energy Traders", 
        component: <EnergyTraders />, 
        navbar:true, 
        footerbar: true, 
        context:"Determine the correct marginal cost of your battery asset",
        contexticon: energytradersicon, 
      },
      {
        path: "/solutions/epc-contractors", 
        category:0, 
        name: "EPC Contractors", 
        component: <EPCContractors />, 
        navbar:true, 
        footerbar: true, 
        context:"Make your battery installation and monitoring simple", 
        contexticon: epcicon,
      },
      {
        path: "/solutions/ipp", 
        category:0, 
        name: "IPP", 
        component: <IPP />, 
        navbar:true, 
        footerbar: true, 
        context:"Effectively monitor and maintain your battery asset", 
        contexticon: ippicon,
      },
      {
        path: "/solutions/project-developers", 
        category:0, 
        name: "Project Developers", 
        component: <ProjectDevelopers />, 
        navbar:true, 
        footerbar: true, 
        context:"Perform battery analysis while project development", 
        contexticon: projectdevelopersicon,
      },
      {
        path: "/solutions/utilities", 
        category:0, 
        name: "Utilities", 
        component: <Utilities />, 
        navbar:true, 
        footerbar: true, 
        context:"Maximize the potential of your battery with cost effective optimization", 
        contexticon: utilitiesicon,
      },
      {
        path: "/solutions/develop", 
        category:1, 
        name: "Develop", 
        component: <Develop />, 
        navbar:true, 
        footerbar: false, 
        context:"Planning your investment for a battery asset", 
        contexticon: developicon,
      },
      {
        path: "/solutions/build", 
        category:1, 
        name: "Build", 
        component: <Build />, 
        navbar:true, 
        footerbar: false, 
        context:"Ensure your battery asset is well integrated in the system", 
        contexticon: buildicon,
      },
      {
        path: "/solutions/operate", 
        category:1, 
        name: "Operate", 
        component: <Operate />, 
        navbar:true, 
        footerbar: false, 
        context:"Optimize your battery performance for increased profits and lifetime", 
        contexticon: operateicon,
      },
      {
        path: "/solutions/end-of-life", 
        category:1, 
        name: "End-of-Life", 
        component: <EndofLife />, 
        navbar:true, 
        footerbar: false, 
        context:"Determine the operational potential of your battery asset", 
        contexticon: endoflifeicon
      }
    ]
  },
  {
    path: "/resources", name: "Resources", component: <Resources />, navbar:true, footerbar: true, subnavbar:false, subfooterbar: true,
    routes:[
      {
        path: "/resources/news", 
        name: "News", 
        component: <News />, 
        navbar:false,
        footerbar: true, 
      },
      {
        path: "/resources/articles", 
        name: "Articles", 
        component: <Articles />, 
        navbar:false,
        footerbar: true, 
        routes:[
          {path: "/resources/articles/optimization-algorithm", name: "Optimization Algorithm", component: <OptimizationAlgorithm />, navbar:false,},
          {path: "/resources/articles/dispatch-and-control", name: "Dispatch Control", component: <DisPatchAndControl />, navbar:false,},
          {path: "/resources/articles/revolutionizing-battery-optimization-a-real-success-story", name: "Results from reLi in Action", component: <RevolutionizingBatteryOptimizationARealSuccessStory />, navbar:false,},
          {path: "/resources/articles/simulation-tool", name: "Simulation Tool", component: <SimulationTool />, navbar:false,},
          {path: "/resources/articles/battery-analytics", name: "Battery Analytics", component: <BatteryAnalyticsTool />, navbar:false,},
          {path: "/resources/articles/battery-state-of-health", name: "Battery State of Health", component: <BatteryStateofHealth />, navbar:false,},
          {path: "/resources/articles/battery-environmental-challenges-and-solutions", name: "Battery Environmental Challenges and Solutions", component: <BatteryEnvironmentalConcerns />, navbar:false,},
          {path: "/resources/articles/grid-stability", name: "Grid Stability in the Modern Energy Ecosystem", component: <GridStability />, navbar:false,},  
          {path: "/resources/articles/optimizing-bess-with-ai", name: "Optimizing BESS: The AI Revolution", component: <AIRevolution />, navbar:false,},  
          {path: "/resources/articles/reliable-battery-data", name: "Why Accurate Battery Data Matters?", component: <BatteryData />, navbar:false,}, 
          {path: "/resources/articles/energy-storage-cost-function-optimization", name: "From Real-Time Profits to Long-Term Gains", component: <CostFunction />, navbar:false,}, 
          {path: "/resources/articles/battery-failures-predictive-maintenance", name: "Preventing Battery Failures with Predictive Maintenance", component: <PredictiveMaintenance />, navbar:false,}, 
          {path: "/resources/articles/optimizing-bess-for-trade-and-longevity", name: "BESS Trading & Optimization", component: <EnergyTrading/>, navbar:false,}, 
          {path: "/resources/articles/optimize-battery-health-lifespan", name: "Best Practices for Maximizing Battery Life", component: <BatteryLifePractices/>, navbar:false,}, 
          {path: "/resources/articles/battery-storage-grid-stability", name: "Battery Storage and the Future of Grid Stability", component: <BESSandGridStability/>, navbar:false,}, 
          {path: "/resources/articles/state-of-health-enhancing-performance", name: "State of Health (SoH) in Energy Storage Systems", component: <StateofHealth/>, navbar:false,},
          {path: "/resources/articles/improve-operations", name: "Enhancing Battery Storage Operations", component: <ImproveOperations/>, navbar:false,}, 
        ]
      },
      {
        path: "/resources/podcast", 
        name: "Podcast", 
        component: <Podcast />, 
        navbar:false,
        footerbar: false, 
      },
      {
        path: "/resources/awards", 
        name: "Awards", 
        component: <Awards />, 
        navbar:false,
        footerbar: false, 
      },
      {
        path: "/resources/demo", 
        name: "Request demo", 
        component: <Demo />, 
        navbar:false,
        footerbar: true, 
      },
    ]
  },
  {
    path: "/careers", name: "Join the team", component: <Careers/>,navbar:false, footerbar: true, 
    routes: [
      {
        path: "/careers/technical-sales-ft", 
        name: "Technical Sales Full Time", 
        component: <TechnicalSalesFT />, 
        navbar:false,
        footerbar: false, 
      },
      {
        path: "/careers/open-application", 
        name: "Open Application", 
        component: <OpenPosition />, 
        navbar:false,
        footerbar: false, 
      },
    ]
  },
  {
    path: "/contact", name: "Contact Us", component: <Contact />, navbar:false, footerbar: true, 
  },
  {
    path: "/about", name: "About", component: <About />, navbar:true, footerbar: true, 
  },
  {
    path: "/privacy-policy", name: "Privacy Policy", component: <PrivacyPolicy />, navbar:false, footerbar: false, 
  },
  {
    path: "/imprint", name: "Imprint", component: <Imprint />, navbar:false, footerbar: false, 
  },
  {
    path: "/terms-and-conditions", name: "Terms and Conditions", component: <TermsAndConditions />, navbar:false, footerbar: false, 
  },
  {
    path: "/login", name: "Login", component: <Login />, navbar:false, footerbar: false, 
  },
  {
    path: "*", name: "Error", component: <Error404/>, navbar:false, footerbar: false, 
  }
];

export default pageRoutes;